import { ApiResponse, IApi } from '../interfaces/iApi';
import { UploadedFile } from '../interfaces/iFiles';
import { CreateApplicant, CreateApplicants, CreateJob, CreateJobResponse, IJobs, Job, ListJobs } from '../interfaces/iJobs';
import { IsNullOrEmpty } from '../utils/utils';


export class JobsService<T> implements IJobs<Job | Job[] | any> {
    private apiService: IApi<T>;
    constructor(type: { new(slug, key): IApi<T>; }, slug: string = '') {
        this.apiService = new type(slug, 'applicationApiPath');
    }

    async cancelJobProcessing(jobId: string): Promise<void> {
        var response = await this.apiService.get(`/${jobId}/cancel`) as ApiResponse<void>;
        return response.data;
    }

    async uploadApplicants(jobId: string, applicants: CreateApplicant[]): Promise<UploadedFile[]> {
        var response = await this.apiService.post<CreateApplicants>({ applicants: applicants }, `/${jobId}/applicants`) as ApiResponse<UploadedFile[]>;
        return response as UploadedFile[];
    }

    async getJobMedia(jobId: string): Promise<UploadedFile> {
        var response = await this.apiService.get(`/${jobId}/media`) as ApiResponse<UploadedFile>;
        return response.data;
    }

    async getJob(jobId: string): Promise<Job> {
        var response = await this.apiService.getOne(jobId);
        return response.data as Job;
    }

    async getJobs(request: ListJobs): Promise<Job[]> {
        var response = await this.apiService.getList(request);
        return response.data as Job[];
    }

    async updateJob(job: Job): Promise<Job> {
        var response = await this.apiService.update(job as any, job._id);
        return response.data as Job;
    }

    async createJob(job: CreateJob): Promise<CreateJobResponse> {
        var response = await this.apiService.create(job as any);
        return response.data as CreateJobResponse;
    }

    async deleteJob(job: Job): Promise<void> {
        await this.apiService.delete(job._id);
    }

}

export function filterJobs(filter: ListJobs, jobs: Job[]): Job[] {
    if (!IsNullOrEmpty(filter.searchTerm))
        jobs = jobs.filter(w => w.name.toLowerCase().includes(filter.searchTerm.toLowerCase()) || w.description?.toLowerCase().includes(filter.searchTerm.toLowerCase()));
    if (filter.skills && filter.skills.length > 0)
        jobs = jobs.filter(w => w.metadata.skills.some(t => filter.skills.includes(t)));
    return jobs.slice(0, filter.itemsPerPage);
}

export function getAllJobSkills(jobs: Job[]): string[] {
    return [... new Set(jobs.flatMap(c => c.metadata.skills))];
}