import { createStore } from "@stencil/store";
import { Job } from "../interfaces/iJobs";
import { Candidate } from "../interfaces/iCandidates";
import { JobsService } from "./jobs";
import { Api } from "./api";
import { UploadFileState, UploadedFileStatus } from "../interfaces/iFiles";
import { FileService } from "./files";

export interface RoundZeroData {
  init(force: boolean, refresh?: number): Promise<void>;
  dispose(): void;
  jobs: Job[];
  candidates: Candidate[];
  files: UploadedFileStatus[];
  uploadProgress: number;
  processingProgress: number;
  updateFile(fileId: string, state: UploadFileState, url?: string, file?: File, jobId?: string, applicantId?: string): void;
  removeJob(job: Job);
}

var refreshTimer: NodeJS.Timer = null;

const { state, onChange: fileListChanged, onChange: candidatesChanged } = createStore<RoundZeroData>({
  async init(force: boolean = false, refresh: number = 30000) {
    if (localStorage.getItem('token') != null) {
      await loadData(force);
      if (refreshTimer == null)
        setTimer(refresh);
    }
  },
  dispose() {
    state.jobs = [];
    clearInterval(refreshTimer);
  },
  jobs: [],
  removeJob(job: Job) {
    state.jobs = state.jobs.filter(w => w._id != job._id);
  },
  uploadProgress: 0,
  processingProgress: 0,
  candidates: [],
  files: [],
  updateFile(fileId: string, uploadFileState: UploadFileState, url?: string, file?: File, jobId?: string, applicantId?: string) {
    debugger;
    let fileIndex = state.files.findIndex(f => f.fileId == fileId);
    if (fileIndex > -1) {
      state.files[fileIndex].state = uploadFileState;
      state.files[fileIndex].lastAction = new Date();
    }
    else
      state.files.push({
        jobId: jobId,
        applicantId: applicantId,
        fileId: fileId,
        state: uploadFileState,
        file: file,
        url: url,
        lastAction: new Date()
      });
    state.files = [...state.files];
    state.uploadProgress = (100 / state.files.length) * state.files.filter(w => w.state == UploadFileState.UploadFinished).length;
    if (state.uploadProgress == 100) {
      state.init(true, 5000);
      setTimer(5000);
    }
    state.processingProgress = (100 / state.files.length) * state.files.filter(w => w.state == UploadFileState.Completed).length;
    if (state.processingProgress == 100) {
      state.init(true, 30000);
      setTimer(30000);
      state.jobs[state.jobs.findIndex(w => w._id == this.jobId)].isProcessingCvs = false;
      state.jobs = [...state.jobs];
    }
  }
});

fileListChanged('files', (files: UploadedFileStatus[]) => {
  if (files.filter(w => w.state == UploadFileState.Uploading).length < 3) {
    if (files.filter(w => w.state == UploadFileState.Queued).length > 0) {
      uploadFile(files.find(w => w.state == UploadFileState.Queued));
    }
  }
});

function setTimer(refresh: number = 30000) {
  clearInterval(refreshTimer);
  refreshTimer = null;
  refreshTimer = setInterval(() => loadData(true), refresh);
}

async function loadData(force: boolean) {
  if (state.jobs.length == 0 || force == true) {
    let jobsApi = new JobsService<Job[]>(Api, 'v1/app/job');
    state.jobs = await jobsApi.getJobs({});
    state.jobs.forEach(j => j.isProcessingCvs = j.applicants.filter(w => !w.isFailed).some(a => a.rank == undefined));
    state.jobs = [...state.jobs];

    state.jobs.forEach(j => {
      if (j.isProcessingCvs) {
        j.applicants.forEach(a => {
          const fileId = `${j._id}_${a._id}.${a.fileName.split('.').pop()}`;
          if (a.rank == null && !state.files.some(w => w.fileId == fileId))
            state.files.push({
              lastAction: new Date(),
              applicantId: a._id,
              jobId: j._id,
              fileId: fileId,
              url: '',
              state: a.isFailed == true ? UploadFileState.Error : (a.rank == null ? UploadFileState.UploadFinished : UploadFileState.Completed),
              file: {
                name: a.fileName
              } as any
            })
        });
      }
    });

    state.uploadProgress = (100 / state.files.length) * state.files.filter(w => w.state == UploadFileState.UploadFinished).length;

    if (state.files && state.files.length > 0) {
      state.jobs.forEach(j => {
        j.applicants.forEach(a => {
          if (state.files.some(w => w.applicantId == a._id)) {
            if (a.rank != null) {
              state.files[state.files.findIndex(w => w.applicantId == a._id)].state = UploadFileState.Completed;
            }
            if (a.isFailed) {
              state.files[state.files.findIndex(w => w.applicantId == a._id)].state = UploadFileState.Error;
            }
            state.files = [...state.files];
            state.processingProgress = (100 / state.files.length) * state.files.filter(w => w.state == UploadFileState.Completed || w.state == UploadFileState.Error).length;
            if (state.processingProgress == 100)
              setTimer(30000);
          }
        });
      });
    }

    if (state.jobs.some(j => j.isProcessingCvs)) {
      setTimer(5000);
    }


  }
  document.dispatchEvent(new Event("jobsAvailable"));
}


function uploadFile(uploadedFileStatus: UploadedFileStatus) {
  const fileService: FileService = new FileService();
  state.updateFile(uploadedFileStatus.fileId, UploadFileState.Uploading);
  fileService.put(uploadedFileStatus.url, uploadedFileStatus.file)
    .then(response => {
      if (response.status == 200)
        state.updateFile(uploadedFileStatus.fileId, UploadFileState.UploadFinished);
      else
        state.updateFile(uploadedFileStatus.fileId, UploadFileState.Error);
    })
    // .finally(() => state.files.find(file => file.fileId == uploadedFileStatus.fileId).file = null)
    .catch(() => state.updateFile(uploadedFileStatus.fileId, UploadFileState.Error));
}

state.init(false, 30000);

export default { state, candidatesChanged };